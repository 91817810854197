@use './../base/color' as color;
@use './../mixins/index.scss' as mixin;

.vacancies {
    .vacancies__wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        position: relative;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            gap: 5rem;
            grid-template-columns: repeat(2, 1fr);
        }

        @include mixin.breakpoint('min', 'tablet-landscape') {
            gap: 5rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .vacancy__wrapper--fade::before {
        content: "";
        position: absolute;
        top: 80%;
        width: 100%;
        height: 30rem;
        display: block;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgb(255, 255, 255) 60%, rgba(9, 9, 121, 0) 100%);
        pointer-events: none;
    }

    .vacancy__image {
        aspect-ratio: 1.5/1;
        background-color: color.$color-blue-trans;
        margin-bottom: 2rem;
    }
}