@use './../mixins/index' as mixin;
@use './../base/index' as base;

.collection {
    padding: 2rem 0;

    @include mixin.breakpoint("min", "tablet-landscape") {
        padding: 4rem 0;
    }

    &__intro__text {
        margin-top: 2rem;
    }
}

.collection__wrapper {
    @extend %content__wrapper--padding;
    @include mixin.sectionwrapper();
}

.collection--spacer {
    @include mixin.breakpoint("min", "tablet-landscape") {
        &:last-child {
            margin-bottom: 7.5rem;
        }
    }
}

.slider__products {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    overflow: hidden;

    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }

    .slick-track {
        @include mixin.flex($gap: 0);
        margin-left: 0;
    }

    .slick-slide {
        @include mixin.flex($type: flex !important, $gap: 0, $align: flex-end);
        height: auto !important;
        margin: 0 1rem;

        @include mixin.breakpoint("min", "tablet-landscape") {
            margin: 0 2.5rem;
        }
    }

    .slick-list {
        margin: 0 -1rem;

        @include mixin.breakpoint("max", "tablet-landscape") {
            padding: 0 10% 0 0;
        }

        @include mixin.breakpoint("min", "tablet-landscape") {
            margin: 0 -2.5rem;
        }
    }
}

.collection {
    &.background--pink {
        .product__price {
            color: base.$color-white;
        }
    }

    &.background--blue {
        .product__price {
            color: base.$color-black;
        }
    }
}

.slick-viewport {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden !important;
}
