@use 'color' as color;
@use '../mixins' as mixin;

@font-face {
    font-family: 'Platform';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../../fonts/Platform-Regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/Platform-Regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
    font-family: 'Platform';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('../../fonts/Platform-Medium.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/Platform-Medium.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
    font-family: 'Calibre';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('../../fonts/Calibre-RegularItalic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/Calibre-RegularItalic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../../fonts/Calibre-Regular.woff2') format('woff2'),
        url('../fonts/Calibre-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('../../fonts/Calibre-Medium.woff2') format('woff2'),
        url('../fonts/Calibre-Medium.woff') format('woff');
}


body {
    font-family: 'Calibre';
    color: color.$color-black;
}

em {
    font-family: 'Calibre';
    font-style: italic;
}


h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    margin-bottom: 1.3rem;
    line-height: 1.08;
    font-family: 'Platform', sans-serif;
    font-weight: 500;
}

h1 {
    font-size: 4rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 5.5rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        font-size: 7.8rem;
    }
}

h2 {
    font-size: 3.2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 4.8rem;
    }

}

h3,
.heading-3 {
    font-size: 2.5rem;
    font-weight: 500;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 3.0rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        font-size: 3.9rem;
    }
}

h4,
.heading-4 {
    font-size: 2.2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 2.9rem;
    }
}

strong {
    font-family: inherit;
    font-weight: 500;
    letter-spacing: inherit;
    line-height: inherit
}

// h5 {
// 	font-size: 1.8rem;

// 	@include mixin.breakpoint('min','tablet-landscape') {
// 		font-size: 2rem;
// 	}
// }

ul,
ol {
    padding-left: 1rem;
}


ul>li {
    @include mixin.font-size(1.8rem, 2rem, mixin.$screen-tablet-landscape);
}

p,
span {
    @include mixin.font-size(1.8rem, 2rem, mixin.$screen-tablet-landscape);
    margin: 0 0;

    ul,
    ol,
    a:not(.button) {
        font-size: inherit;
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.heading--uppercase {
    text-transform: uppercase;
}


.heading--margin--big {
    margin-bottom: 7rem;
}

.heading__margin--small {
    margin-bottom: 1rem;
}

.heading__margin {
    margin-bottom: 2rem;
}

.heading__margin--none {
    margin-bottom: unset;
}

.heading--centered {
    display: flex;
    flex: 1;
    justify-content: center;
}

.heading--v-centered {
    align-self: center;
}

.heading__width--small {
    width: 75%;
}
