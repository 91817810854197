@use './../base/color' as color;
@use '../mixins/index' as mixin;

$bg-colors: 'darkblue', 'lightblue', 'pink', 'orange';
$bg-colors-trans: 'blue', 'lightblue', 'pink', 'orange';

// bg colors soft
@each $bg-color in $bg-colors {
    .bg--color-#{$bg-color}-soft {
        background-color: var(--color-#{$bg-color}-soft);
    }
}

// bg colors
@each $bg-color in $bg-colors-trans {
    .bg--color-#{$bg-color}-trans{
        background-color: var(--color-#{$bg-color}-trans);
    }
}

.bg--color-blue,
.bg--color-orange {
    color: #fff;
}
