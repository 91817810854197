@use './../mixins/index' as mixin;
@use './../base/color' as color;

.socials {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
}

.social {
    width: 4rem;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 100%;
    @include mixin.flex($align: center, $justify: center);

    svg {
        width: 2rem;
        height: 2rem;
    }
}

.footer__socials .social {
    background-color: color.$color-white;
}

.contact__socials {
    .social {
        background-color: color.$color-pink-soft;

        path {
            fill: color.$color-white;
        }

    }
}