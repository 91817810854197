.appear {
    animation: appear 1.5s ease-in;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
