@use '../mixins/index' as mixin;
@use '../base/color' as color;

html,
body {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

html {
    font-size: 62.5%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    font: inherit;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    vertical-align: bottom;
}

textarea:focus,
input:focus,
button:focus {
    outline: none;
}

.content {

    p a:not(.button),
    li a:not(.button) {
        text-decoration: underline;
        color: color.$color-pink-soft;
    }
}



button {
    cursor: pointer;
}

main {
    position: relative;
    margin-top: 11rem;
}

input,
textarea {
    appearance: none;
    border-radius: 0;
}

input[type=radio],
input[type=checkbox] {
    cursor: pointer;
    width: auto !important;
}

input[type="radio"] {
    appearance: radio;
}

input[type=checkbox] {
    appearance: checkbox;
}

// ::-webkit-input-placeholder {
//   color: $color-blue;
// }
// ::-moz-placeholder {
//   color: $color-blue;
// }
// :-ms-input-placeholder {
//   color: $color-blue;
// }
// :-moz-placeholder {
//   color: $color-blue;
// }

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.scroll-no {
    height: 100%;
    overflow: hidden;
}

p>img,
.embed {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 2rem 0;

    iframe {
        max-width: 100%;
    }

    @include mixin.breakpoint('min', 'tablet-portrait') {
        width: unset;
    }
}

details summary::-webkit-details-marker {
    display: none;
}

.content--small {
    padding: 5rem 0 !important;

    @include mixin.breakpoint('min', 'desktop') {
        padding: 7rem 0 0 0 !important;
    }
}

.content,
.element {
    padding: 4rem 0;

    @include mixin.breakpoint('min', 'desktop') {
        padding: 5rem 0;
    }
}

.content__wrapper,
.element__wrapper {
    @include mixin.sectionwrapper();
}

.content__title,
.element__title {
    h2 {
        margin-bottom: 0;
    }

    margin-bottom:2rem;

    @include mixin.breakpoint('min', 'desktop') {
        margin-bottom: 4rem;
    }
}
