@use '../mixins/index' as mixin;

.instagram__wrapper {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 26rem), 1fr));
    }
}

.instagram__item {
    aspect-ratio: 1/1;
}