@use '../mixins/index' as mixin;
@use '../base/color' as color;

.news__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 26rem), 1fr));
    gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        gap: 5rem;
    }
}

.news {
    transition: all .2s ease-in-out;
}

.news:hover,
.news:focus {
    transform: scale(1.02);
}

.news__image {
    margin-bottom: 4rem;
}

.news__image {
    aspect-ratio: 1.5/1;
    margin-bottom: 2rem;

    width: 100%;
    object-fit: cover;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        height: 250px;
    }
}

.news__content {
    margin-top: 2rem;
}