@use '../mixins/index' as mixin;
@use '../base/index' as base;

.artist__list {
    display: grid;
    grid-gap: 4.5rem;
    padding: 5rem 0;
    grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(15rem, 15%)), 1fr));
}

.artist__link {
    @include mixin.flex($gap: 0, $direction: column, $align: center, $justify: center);
    border-radius: 2rem;
    transition: background-color .2s ease;

    &:hover,
    &:focus,
    &:active {
        .artist__image {
            transform: scale(1.05);
        }
    }
}

.artist__image {
    width: 100%;
    max-width: 26rem;
    aspect-ratio: 1;
    overflow: hidden;
    margin-bottom: 2rem;
    overflow: hidden;
    transition: transform .2s linear;
    border-radius: 50%;
}

.artist__name {
    font-weight: 500;
    font-size: 1.6rem;
    text-align: center;
}

.about {
    // padding: 7.5rem 0 5rem;

    // @include mixin.breakpoint('min', 'tablet-landscape') {
    //     padding: 15rem 0 7.5rem;
    // }
}

.content__wrapper--border-bottom {
    border-bottom: solid 1.5px;
}

.content__wrapper--no-padding {
    padding: 0;
}

.about__wrapper {
    @extend %content__wrapper;
    @include mixin.sectionwrapper();
}

.about__body {
    @include mixin.flex($gap: 0, $direction: column, $breakpoint: 'tablet-landscape', $justify: space-between);
}

.about__image {

    .image--reposition {
        @include mixin.breakpoint('min', 'tablet-landscape') {
            position: absolute;
            top: -7.5rem;
            box-shadow: 0 .2rem 5rem -.1rem rgba(base.$color-black, .13);
            z-index: 2;
        }
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        position: relative;
        flex: 0 0 45%;

        &:after {
            content: '';
            width: 25rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.about__content {
    @include mixin.flex($gap: 0, $direction: column);
    flex: 0 0 55%;
    padding: 5rem 0;
    font-size: 1.6rem;
    color: base.$color-black;

    * {
        color: inherit;
    }

    p {
        font-size: 1.875rem;
    }

    @include mixin.breakpoint('max', 'tablet-landscape') {
        align-items: center;
        text-align: center;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        font-size: 1.8rem;
    }
}

.about__item {
    padding: 3rem 3rem;

    * {
        color: base.$color-white;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        padding: 6rem 12rem 6rem 6rem;
    }
}



.inspiration__wrapper {
    @include mixin.flex($gap: 0, $dynamicDirection: true, $breakpoint: 'tablet-landscape');
}

.inspiration__content,
.inspiration__image {
    @include mixin.flex($gap: 0, $direction: column);
    flex: 1;
}

.inspiration__image {
    max-width: 33%;
    align-items: flex-end;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        max-width: 100%;
        align-items: center;
    }
}

.inspiration__content {
    min-width: 66%;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        min-width: 0;
    }

    span:not(:last-child) {
        margin-bottom: 0;
    }
}

.inspiration__title {
    font-size: 1.875;
    font-weight: 500;
}

.image__boxed {
    padding: 3rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        max-width: 75%;
        padding: 0 0;
    }
}

.content__text--flex {
    @include mixin.flex($direction: column, $gap: 2rem, $align: flex-start);

    @include mixin.breakpoint('min', 'tablet-landscape') {
        @include mixin.flex($direction: row, $gap: 2rem, $align: center);
    }
}


.content__text--flex.content__text--flex-between {
    @include mixin.flex($direction: row, $wrap: wrap, $gap: 2rem, $align: flex-start);

    .content__title--small-width {
        @include mixin.breakpoint('max', 'tablet-landscape') {
            width: 100%;
            max-width: initial;
        }

        @include mixin.breakpoint('min', 'desktop') {
            max-width: 33%;
        }
    }
}


.content__text--flex-between {
    justify-content: space-between;
}



.content__title--no-margin {
    margin-bottom: 0;
}

.content__title--small-width {
    max-width: 33%;
}

.works__slider {
    @include mixin.flex($gap: 0);
}

.art__images {

    .image-tab {
        position: relative;

        .product__image-unavailable {
            z-index: 10;
        }
    }
}
