@use './../mixins/index' as mixin;
@use './../base/color' as color;

.product {
    @include mixin.flex($direction: column);
    margin-top: auto;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        .product__favorite {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    figure {
        margin: 0;
    }
}

.product__info {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;

    @include mixin.breakpoint('max', 'mobile') {
        @include mixin.flex($gap: 0, $direction: column);
        margin: auto;
    }
}

.product__list {
    flex-direction: row;

    a {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;

        // grid-template-columns: repeat(2, minmax(0, 1fr));
        @include mixin.breakpoint('max', 'mobile') {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.product__image {
    position: relative;
    margin-bottom: 3rem !important;
    overflow: hidden;
    display: inline-flex;
}

.product__image-padding {
    padding: 2rem;
    margin-bottom: 0rem !important;

    picture {
        overflow: hidden;
    }
}

.product__image--small {
    max-width: 150px;
    max-height: 150px;
}

.product__image-unavailable {
    @include mixin.flex($align: center, $justify: center);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 6rem;
    color: color.$color-orange-soft;
    background-color: rgba(color.$color-white, .85);
    font-size: 1.4rem;
    font-weight: 500;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        font-size: 1.6rem;
    }
}

.product__name {
    @include mixin.flex($align: center, $justify: space-between);
}

.product__title {
    flex: 1;
    font-size: 2rem !important;
    margin: 0 0;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        @include mixin.flex($align: center);
        line-height: 27px;
    }
}

.product__author {
    font-size: 1.8rem !important;
    font-weight: 400;
    margin: 1rem 0 0.5rem 0;

    @include mixin.flex($align: center, $gap: 0);

    &:not(:last-child) {
        margin-bottom: 0;
    }
}

.product__author--list {
    font-size: 2rem !important;
    margin: 0;
    text-align: left;
    line-height: 40px;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        font-size: 1.6rem !important;
        line-height: 26px;
    }

}

.product__size {
    font-weight: 400;
    font-size: 1.8rem;
    letter-spacing: .5px;

    @include mixin.flex($align: center, $gap: 0);

    @include mixin.breakpoint('max', 'tablet-landscape') {
        font-size: 1.6rem !important;
    }
}

.product__size--list {
    font-weight: 500;
    font-size: 2rem;
    text-align: left;
    line-height: 40px;
    margin-left: 1rem;

    @include mixin.breakpoint('max', 'mobile') {
        margin-left: 0rem;
        line-height: 26px;
        font-size: 1.6rem;
    }
}

.product__link {
    outline: none;
}

.product__selector {
    flex: 0 0 6rem;

    .checkbox__field {
        margin: 0 0;
    }
}

.product__favorite {
    @include mixin.flex($align: center, $justify: center);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background-color: color.$color-white;
    z-index: 2;
    transform: translateY(100%);
    transition: transform .2s ease-in;

    .favorite__icon {
        width: 2.4rem;
        height: 2.4rem;

        &:hover {
            svg {
                animation: bounce 0.82s cubic-bezier(.36, .07, .19, .97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
        }

        svg {
            width: 24px;
            height: 22px;
        }
    }
}

.product__favorite--active {
    transform: translateY(0);
}

.product__unavailable {

    .product__title,
    .product__author,
    .product__price {
        opacity: .2;
    }
}