@use '../base/color' as color;
@use '../mixins/index' as mixin;
@use '../partials/buttons';

.element.dnadesign__elementaluserforms__model__elementform {
    padding: 0;
}

.dnadesign__elementaluserforms__model__elementform .userform {
    display: grid;
    gap: 2rem;
    margin-block: 4rem;
    clear: none;
    width: initial;
    height: initial;

    .userform-fields .userformsstep {
        display: grid;
        gap: 2rem;
        grid-auto-columns: 1fr;

        @include mixin.breakpoint('min', 'mobile') {
            grid-template-columns: repeat(2, 1fr);

        }
    }

    .field.optionset,
    .field.userformsoptionset {
        label {
            display: block;
        }


        .odd,
        .even {
            @include mixin.flex($align: center);
            margin-bottom: 1rem;
            font-size: 2rem;
            color: color.$color-blue-trans;
        }
    }

    // .field,
    // .middleColumn {
    //     width: 100%;
    // }

    .field label {
        display: none;
    }

    .field input,
    .field textarea {
        width: 100%;
        box-shadow: 0px 2px 36px 2px rgba(201, 201, 201, 0.349649);
        background-color: rgba(255, 255, 255, 1);
        color: gray;
        border: 0;
        font-size: 2rem;
    }

    .field textarea {
        resize: vertical;
    }

    .field.textarea,
    .field.file {
        grid-column-start: 1;
        grid-column-end: -1;
    }

    .btn-toolbar input[type="submit"] {
        @extend .button;
        color: color.$color-white;

        &:hover {
            color: #fff;
            background-color: color.$color-orange-trans;
        }
    }

    input[type="submit"]:hover {
        cursor: pointer;
    }
}
