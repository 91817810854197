@use './../mixins/index' as mixin;

.showcase {
    display: grid;
    gap: 1rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mixin.breakpoint('min', 'desktop') {
        grid-template-columns: repeat(4, 1fr);
    }

    img {
        height: 40rem;
        object-fit: cover;
    }

    &__subtitle {
        display: inline-block;
        margin-top: 3rem;
        font-size: 2rem;
        font-weight: 700;
        font-family: "Platform";
    }

    &--original-ratios {
        display: block;
        width: 100%;

        img {
            height: auto;
        }
    }

    &--slider {
        margin: 3rem 0;

        .slick-slide {
            margin: 0 .5rem;
        }

        .slick-list {
            margin: 0 -0.5rem;
        }
    }
}
