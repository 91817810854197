$color-white: #FFFFFF;
$color-black: #000000;

$color-darkblue-soft: #1A396E;
$color-lightblue-soft: #AFD1F0;
$color-pink-soft: #FFCDC6;
$color-orange-soft: #FF943B;

$color-black-trans: #000;
$color-blue-trans: #8D9BB6;
$color-lightblue-trans: #D5E7F7;
$color-pink-trans: #FDE4E1;
$color-orange-trans: #FCC9A2;

:root {
    --color-darkblue-soft: #1A396E;
    --color-lightblue-soft: #AFD1F0;
    --color-pink-soft: #FFCDC6;
    --color-orange-soft: #FF943B;
    --color-black-trans: #000;
    --color-blue-trans: #8D9BB6;
    --color-lightblue-trans: #D5E7F7;
    --color-pink-trans: #FDE4E1;
    --color-orange-trans: #FCC9A2;
}
