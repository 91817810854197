@use "../mixins/index" as mixin;

@keyframes dots {
    0%, 20% { content: '.' }
    40% { content: '..' }
    60% { content: '...' }
    90%, 100% { content: '' }
}

.artist__loader {
    margin: 10rem auto;
    max-width: 60rem;

    h3:after {
        content: "";
        animation: dots 2s linear infinite;
    }
}

.artist__list_alphabet-wrapper {
    margin: 5rem 0;
}

.artist__list_search-results {
    display: flex;
    justify-content: center;
}

.artist__list_alphabet {
    margin-bottom: 5rem;

    &_group {
        display: flex;
        flex-wrap: wrap;
        min-height: 8rem;
        flex-direction: column;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            flex-direction: row;
        }
    }

    .artist__alphabet {
        flex-basis: 25%;
        margin: 8px 0;
        display: block;

        &:hover {

            svg {
                opacity: 1;
                translate: 3px 0;
            }
        }

        svg {
            opacity: 0;
            width: 1.5rem;
            position: relative;
            top: 9px;
            left: 8px;
            transition: all .2s ease-in-out;
        }
    }
}
