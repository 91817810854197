@use '../mixins/index' as mixin;

.reviews__wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 3rem;

    @include mixin.breakpoint('min', 'desktop') {
        gap: 5rem;
    }

    .slider__wrapper {
        padding: 0;
    }
}

.review {
    cursor: pointer;
    min-width: 26rem;

    svg path {
        fill: #FFCDC6;
    }
}

.review p.description {
    font-family: 'Platform';
    line-height: 1.7;
    font-size: 2rem;

    @include mixin.breakpoint('min', 'desktop') {
        padding-right: 4rem;
    }
}

.review p.name {
    font-weight: 500;
}
