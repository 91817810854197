@use '../base/color' as color;
@use '../mixins/index' as mixin;

.prices__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 26rem), 1fr));
    gap: 5rem;
}

.pricing {
    background-color: color.$color-white;
    box-shadow: 0 2px 36px 2px rgba(201, 201, 201, 0.35);


    h4 {
        padding: 2rem 2.5rem;
        margin-bottom: 0;
    }
}

.pricing__content {
    padding: 2rem 2.5rem;
}

span.price {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    display: block;
    font-family: 'Platform';
    font-weight: 400;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        font-size: 2.4rem;
    }
}