@use 'breakpoints' as breakpoint;

@mixin sectionwrapper($maxWidth: null,
    $setBreakpointMaxWidth: null,
    $setBreakpointMaxWidthSize: null,
    $setDefaultPadding: null,
    $setBreakpointPadding: null,
    $setBreakpointPaddingSize: null,
    $unsetPadding: null) {
    margin: 0 auto;
    width: 100%;

    @if ($maxWidth !=null) {
        max-width: $maxWidth;
    }

    @else {
        max-width: 130rem;
    }

    @if $setDefaultPadding !=null {
        padding: $setDefaultPadding;
    }

    @else {
        padding: 0 2.5rem;
    }

    @if ($setBreakpointMaxWidth !=null and $setBreakpointMaxWidthSize !=null) {
        @include breakpoint.breakpoint('min', $setBreakpointMaxWidth) {
            max-width: $setBreakpointMaxWidthSize;
        }
    }

    @if ($setBreakpointPadding !=null and $setBreakpointPaddingSize !=null) {
        @include breakpoint.breakpoint('min', $setBreakpointPadding) {
            padding: $setBreakpointPaddingSize;
        }
    }

    @if ($unsetPadding !=null and $unsetPadding ==true) {
        @if ($setBreakpointMaxWidth !=null) {
            @include breakpoint.breakpoint('min', $setBreakpointMaxWidth) {
                padding: 0 0;
            }
        }

        @else {
            @include breakpoint.breakpoint('min', 'desktop-large') {
                padding: 0 0;
            }
        }
    }
}