@use '../mixins/index' as mixin;

.list__wrapper {
    display: grid;
    gap: 2rem;

    @include mixin.breakpoint('min', 'desktop') {
        gap: 5rem;
    }
}

.list__item {
    display: grid;
    gap: 2rem;
}

.list__wrapper[data-layout="small-list"] {
    .list__image {
        width: clamp(7rem, 1.5vw, 100%)
    }

    .list__item:has(.list__image) {
        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: 10rem 1fr;
            gap: 5rem;
        }
    }
}

.list__wrapper[data-layout="big-list"] {
    .list__item:has(.list__image) {
        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: 18rem 1fr;
            gap: 5rem;
        }
    }
}