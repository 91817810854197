@use '../mixins/index' as mixin;
@use '../base/color' as color;

%default__spacer {
    padding: 3rem 0;

    @include mixin.breakpoint('min','desktop-xlarge') {
        padding: 5rem 0;
    }
}

%default__spacer--big {
    padding: 7.5rem 0 7.5rem;

    @include mixin.breakpoint('min','tablet-landscape') {
        padding: 10rem 0 10rem;
    }
}

%text--uppercase {
    text-transform: uppercase;
}

.background--colored {
    background-color: color.$color-pink-soft;
}

.background--blue {
    background-color: color.$color-lightblue-trans;
}

.background--pink-soft {
    background-color: color.$color-pink-soft;
}

.text--big {
    font-size: 1.6rem;

    @include mixin.breakpoint('min','tablet-landscape') {
        font-size: 2rem;
    }
}

.text--small {
    font-size: 1.4rem;

    @include mixin.breakpoint('min','tablet-landscape') {
        font-size: 1.6rem;
    }
}

p:has(.center) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text--pink {
    color: color.$color-pink-soft;
}

.text--blue {
    color: color.$color-lightblue-trans;
}

.text--orange {
    color: color.$color-orange-soft;
}

.text--black {
    color: color.$color-black;
}

.text--center {
    text-align: center;
    width: 100%;
}

.text--disabled {
    color: color.$color-blue-trans;
    opacity: 0.54;
}

.text--lowercase {
    text-transform: lowercase !important;
}

.text--capitialize {
    display: inline-block;
    text-transform: lowercase;

    &:first-letter {
        text-transform: capitalize;
    }
}

.opacity--0 {
    opacity: 0;
}

.opacity--100 {
    opacity: 1;
}

.duration--300 {
    transition-duration: .3s;
}

.duration--200 {
    transition-duration: .2s;
}

.ease--in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease--out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.scale--95 {
    transform: scale(.95);
}

.scale--100 {
    transform: scale(1);
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.align-start {
    align-items: start !important;
}

.align-end {
    align-items: end !important;
}

.gap-medium-mobile {
    @include mixin.breakpoint('max', 'tablet-portrait') {
        gap: 4rem !important;
    }
}

.align-content-center {
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
