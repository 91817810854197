@use './../mixins/index' as mixin;
@use './../base/index' as base;
@import '~slick-carousel/slick/slick.scss';

.slider__control--initialized {
    opacity: 1;
    visibility: visible;
}

.control__icon {
    display: block;
    width: 2.2rem;
    height: 2rem;
    cursor: pointer;
}

.control__previous,
.control__next {
    @include mixin.flex($align: center, $justify: center, $gap: 0);
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    transition: background-color .2s ease;
    will-change: background-color;
}

.control--inline {
    @include mixin.flex($gap: 0, $justify: flex-end);
}



.slick-arrow {
    cursor: pointer;
}

.slider {
    padding: 8rem 0rem 6rem;

    .slick-slide {
        margin: 0 20px;
    }

    .slick-track {
        @include mixin.flex($align: center);
    }
}

.slider__wrapper,
.slider__arrows {
    @include mixin.sectionwrapper();
}

.slider__arrows {
    margin-top: 4rem;
    @include mixin.flex($justify: center);
}


.ArtObjectSlider {

    .slick-slider {
        margin: 4rem 2rem;
    }

    .slick-list {
        margin: 0 -15rem;
    }

    .slick-slide {
        img {
            border-radius: 8px;
            color: #3498db;
            font-size: 36px;
            line-height: 100px;
            position: relative;
            text-align: center;
            transition: all 300ms ease;
            bottom: 0;

            .top {
                font-size: 70%;
                height: 70%;
                margin: 0 auto;

            }

            .bottom {
                font-size: 70%;
                width: 70%;
                margin: 0 auto;

            }
        }
    }

    .slick-current {
        img {
            color: #e67e22;

            .top {
                font-size: 90%;
                width: 90%;
                background-color: rgba(blue, 0.3);
            }

            .bottom {
                font-size: 90%;
                width: 90%;
                background-color: rgba(green, 0.3);
            }
        }
    }
}

.slider,
.ArtObjectSlider {
    .slick-slide img {
        transform: scale(0.33);

    }

    .slick-current img {
        transform: scale(1);
        opacity: 1;
    }
}