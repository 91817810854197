@use "../base/color" as color;
@use "../mixins" as mixin;

.zoho-webform {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    flex-direction: column;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        flex-direction: row;
    }

    &--position-left {

        @include mixin.breakpoint('min', 'tablet-landscape') {
            flex-direction: row-reverse;
        }
    }

    &__content {
        max-width: 55rem;
    }

    iframe {
        border: 0;
    }
}
