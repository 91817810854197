@use '../mixins/index.scss' as mixin;
@use '../base/color' as color;

header {
    padding: 2rem 0;
    z-index: 100;
    position: fixed;
    height: 11rem;
    width: 100%;
    background-color: color.$color-white;
    top: 0;
}

.header__wrapper {
    @include mixin.sectionwrapper();
    @include mixin.flex($align: center, $justify: space-between);
}

.header__brand {

    img,
    svg {
        width: 100%;
        max-width: 11.2rem;
        height: 100%;
        max-height: 7.2rem;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            min-width: 15.2rem;
        }
    }
}

.header__navigation {
    @include mixin.breakpoint('max', 'tablet-landscape') {
        position: fixed;
        top: 10rem;
        left: 0;
        transition: all .4s ease-in-out;
        width: 0%;
        background-color: color.$color-white;
        overflow: hidden;
        height: 100%;
        padding: 4rem 0;
        opacity: 0;


        &.is-active {
            width: 100%;
            background-color: color.$color-black;
            color: color.$color-white;
            padding: 4rem 2.5rem;
            opacity: 1;

            svg {
                fill: color.$color-white;
            }
        }


    }

    a.button {
        @include mixin.breakpoint('min', 'desktop') {
            margin-left: 15rem;
        }
    }
}


nav ul {
    list-style: none;
    padding-left: 0;
    @include mixin.flex($direction: column);

    @include mixin.breakpoint('min', 'tablet-landscape') {
        padding: 0;
        @include mixin.flex($direction: row, $align: center, $justify: space-between);
    }
}

nav ul li {
    display: inline-block;
    text-align: left;
}

nav a {
    display: block;
    transition: color 0.2s;
    color: var(--link-color);
    font-size: 18px;
    line-height: 2;
    text-decoration: none;
}

nav .navigation__icon {

    display: flex;
    cursor: pointer;


    svg {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: -2px;

        @include mixin.breakpoint('min', 'desktop') {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

nav ul>li:hover ul.dropdown {
    max-height: 50rem;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.22);
    border-radius: .5rem;
    padding: 1rem 0;

    li {
        width: 100%;
    }
}


nav ul ul {
    max-height: 0;
    margin-left: -2rem;
    transition: max-height .2s ease-in-out;
    overflow: hidden;
    @include mixin.flex($direction: column, $align: baseline, $gap: 0);

    @include mixin.breakpoint('min', 'tablet-landscape') {
        position: absolute;
        background: color.$color-white;
        color: color.$color-black;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

}

nav ul ul li {
    display: list-item;
    position: relative;

    a.navigation__link {
        font-weight: 400;
        font-size: 1.6rem;
        margin-top: 0;
        padding-top: .7rem;
        line-height: 1.8;
    }
}

nav ul ul li a {
    padding: 0 2rem;
}

.navigation__children .navigation__link {
    @include mixin.breakpoint('max', 'tablet-portrait') {
        justify-content: space-between;
    }
}

header .navigation__link {
    @include mixin.breakpoint('min', 'desktop') {
        margin-top: 1rem;
    }
}

.navigation__link {
    font-size: 2.5rem;
    font-family: 'Calibre';
    @include mixin.flex($gap: .5rem);
    font-weight: 500;
    align-items: center;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 1.8rem;
    }

    span {
        margin-bottom: 0;
        font-size: inherit;
        font-weight: inherit;
    }
}

.navigation__link[aria-expanded="true"] svg {
    transform: scaleX(-1);
}

.menu__toggle {
    appearance: none;
    border: 0;
    background-color: color.$color-white;
    color: color.$color-black;
    border-radius: 1rem;
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.is-active {
        span {
            background-color: color.$color-white;

            &:before,
            &:after {
                inset: 0;
                width: 2.5rem;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg)
            }
        }
    }

    &:hover,
    &:focus {
        span {

            &:before,
            &:after {
                width: 2.5rem;
            }
        }
    }

    span {
        width: 2.5rem;
        height: 0.2rem;
        position: relative;
        background-color: color.$color-black;
        border-radius: 1rem;
        transition: all .2s ease-in-out;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1.5rem;
            height: .2rem;
            border-radius: 1rem;
            ;
            background-color: color.$color-black;
            transition: all .2s ease-in-out;
        }

        &:before {
            top: -.7rem;
            right: 0;
        }

        &:after {
            bottom: -.7rem;
            left: 0;
        }
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        display: none;
    }
}
