@use './../mixins/index' as mixin;


.steps__wrapper {
    display: grid;
    gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        gap: 5rem;
    }
}

.steps__wrapper[data-direction="horizontal"] {
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 26rem), 1fr));
}

.steps__wrapper[data-direction="vertical"] .step {
    display: grid;
    grid-template-columns: 15rem 1fr;
    text-align: left;
    align-items: center;
    gap: 5rem;

    .step__image {
        aspect-ratio: 2/1;
    }
}

.step {
    text-align: center;
}

.step__image {
    max-width: 15rem;
    width: 100%;
    margin-inline: auto;
}

.step__content {
    max-width: 35rem;
    margin-inline: auto;

    h3 {
        @include mixin.breakpoint('min', 'tablet-portrait') {
            font-size: 2.9rem;
        }
    }
}