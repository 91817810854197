@use 'breakpoints' as breakpoint;

$default-mobile-size: 1.6rem;
$default-desktop-size: 1.8rem;

@mixin font-size($mobile: $default-mobile-size,
    $desktop: $default-desktop-size,
    $breakpoint: breakpoint.$screen-mobile,
    $line-height: 1.5,
    $font-weight: 400) {
    font-size: $mobile;
    line-height: $line-height;
    font-weight: $font-weight;

    @if $desktop !=null and $breakpoint !=null {
        @media (min-width: $breakpoint) {
            font-size: $desktop;
        }
    }
}