@use './../base/color' as color;

.share {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    &__link {
        fill: color.$color-pink-soft;
    }
}
