@use './../base/color' as color;
@use './../mixins/index.scss' as mixin;

.contentblock {
    overflow: hidden;
}

.customerblock:not(.bg--swirl-red-dark) .element {
    padding: 3rem 0;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        padding: 5rem 0;
    }
}

.customers-cases {
    .customers__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 28rem), 1fr));
        gap: 2rem;
        position: relative;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            gap: 5rem;
        }
    }

    .customer__wrapper--fade::before {
        content: "";
        position: absolute;
        top: 80%;
        width: 100%;
        height: 30rem;
        display: block;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgb(255, 255, 255) 60%, rgba(9, 9, 121, 0) 100%);
        pointer-events: none;
    }

    .customer__image {
        aspect-ratio: 1.5/1;
        background-color: color.$color-blue-trans;
        margin-bottom: 2rem;
    }
}

.logo-slider {
    .logo-slider__wrapper {
        position: relative;
    }

    .logo-slider__slider {
        margin: 0 4rem;
    }

    .logo-slider-next,
    .logo-slider-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .logo-slider-next {
        right: 0;
    }

    .logo-slider__image img {
        width: 35%;
        margin-inline: auto;
        height: 50%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}

.customer {
    transition: all .2s ease-in-out;
}

.customer:hover,
.customer:focus {
    transform: scale(1.02);
}