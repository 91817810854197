@use './../mixins/index' as mixin;
@use './../base/index' as base;
@use './../partials/index' as partials;

%form__label {
    @include mixin.flex($align: center);
    margin-bottom: 0.5rem;
    font-size: 1.4rem;

    @include mixin.breakpoint('min', 'desktop-large') {
        font-size: 1.6rem;
        letter-spacing: .05rem;
    }
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

input,
select,
textarea {
    appearance: none;
    background: none;
    font-family: inherit;
    font-size: 1.4rem;
    outline: none;
    cursor: text;
    border-radius: 0;

    @include mixin.breakpoint('min', 'desktop-large') {
        border-width: 2px;
        font-size: 1.6rem;
    }
}

input,
select {
    outline: none;

    &:not([type="checkbox"]):not([type="radio"]) {
        height: 5rem;
        padding: 0 2rem;

        @include mixin.breakpoint('min', 'desktop-large') {
            height: 5rem;
        }
    }
}

select {
    width: 100%;
    background: url("../../images/svg/chevron-down.svg") no-repeat 87% transparent;
    background-size: 1.2rem;
    border-radius: .5rem;
}

.select__opaque {
    background-color: rgba(255, 255, 255, 1);
}

textarea {
    padding: 1.5rem 2rem;
}

.form__box {
    margin-top: 1rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-top: 2rem;
    }
}

.form__fields {
    @include mixin.flex($direction: column, $wrap: wrap);
    border: 0;
    padding: 0 0;
    margin: 0 0;
}

.form__row {
    @include mixin.flex;

    &:not(:last-child) {
        margin-bottom: 2rem;

        @include mixin.breakpoint('min', 'tablet-landscape') {
            margin-bottom: 3rem;
        }
    }

    @include mixin.breakpoint('max', 'tablet-landscape') {
        flex-wrap: wrap;

        .form__group {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}

.form__row--small {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.form__group--row {
    @include mixin.flex($align: center, $direction: row);
}

.form__group {
    @include mixin.flex($direction: column);
    flex: 0 0 100%;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        flex: 1;
    }
}

.form__group--spacer {
    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-right: 2rem;
    }
}

.form__group--row {
    @include mixin.flex($align: center, $direction: row);
}

.form__submit {
    margin-top: 3rem;

    .action {
        @extend .button;
        @extend .button--text-white;
        border: 0;
        min-width: 17.5rem;
        cursor: pointer;
        width: 100%;
    }
}

.form__label {
    @extend %form__label;
}

.label__required {
    color: base.$color-orange-soft;
    margin: 0 0.5rem;
    font-size: 1.6rem;
}

.field__input--white {
    background-color: base.$color-white;
    color: base.$color-blue-trans;
}

.form__row--last {
    margin-bottom: 0 !important;
}

.form__section {
    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 2rem;

        @include mixin.breakpoint('min', 'tablet-landscape') {
            margin-bottom: 5rem;
        }
    }
}

.form__inline {
    input {
        border: 0;
    }
}

.field__input--full {
    flex: 1;
}

.form__note {
    margin: 3rem 0 2rem;
    font-size: 1.4rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin: 4rem 0 2rem;
        text-align: center;
    }
}

.fields__disabled {
    opacity: .2;

    input {
        color: base.$color-blue-trans;
        pointer-events: none;
    }
}

.field__special {
    font-size: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border: none !important;
}

.form__message {
    margin-bottom: 3rem;
}

.form__message--invalid {
    padding: 1rem 1.5rem;
    background-color: base.$color-pink-soft;
    color: base.$color-white;
    font-size: 1.4rem !important;
}

.form--inactive {
    opacity: .2;

    .submit--inactive {
        pointer-events: none;
        cursor: not-allowed;
    }
}

.block__discount {
    display: flex;
    flex-direction: column;
}

.block__discount--input {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
}

#discount-code {
    width: 100%;
    margin-right: 1rem;
    height: 5.5rem;
}

#discount-btn {
    width: 100%;
}

#discount {
    width: 100%;
}