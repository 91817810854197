@use 'breakpoints' as breakpoint;

@mixin flex(
    $dynamicDirection: false,
    $breakpoint: 'desktop',
    $type: null,
    $direction: null,
    $align: flex-start,
    $justify: baseline,
    $wrap: null,
    $gap:2rem
) {


  @if($gap) {
    gap:$gap;
  }
  @if ($type) {
    display: $type;
  } @else {
    display: flex;
  }

  @if ($wrap) {
    flex-wrap: $wrap;
  }
  @if ($direction != null) {
    flex-direction: $direction;
  }
  @if ($align != flex-start) {
    align-items: $align;
  }

  @if ($justify != baseline) {
    justify-content: $justify;
  }

  @if ($dynamicDirection and $breakpoint) {
    flex-direction: column;

    @include breakpoint.breakpoint('min', $breakpoint) {
      flex-direction: row
    }
  }
}
