$screen-mobile: 36.25em;
$screen-tablet-portrait: 48em;
$screen-tablet-landscape: 64em;
$screen-desktop: 85em;
$screen-desktop-large: 90em;
$screen-desktop-xlarge: 120em;

$screen-desktop-xxlarge: screen and (min-width : 120em);

$screen-max-mobile: screen and (max-width : 32.4375em);
$screen-max-tablet-portrait: screen and (max-width : 47.9375em);
$screen-max-tablet-landscape: screen and (max-width : 61.9375em);
$screen-max-desktop: screen and (max-width : 76.4375em);
$screen-max-desktop-large: screen and (max-width : 82.4375em);
$screen-max-desktop-xlarge: screen and (max-width : 104.9375em);

$breakpoints: (
    "mobile": $screen-mobile,
    "tablet-portrait": $screen-tablet-portrait,
    "tablet-landscape": $screen-tablet-landscape,
    "desktop": $screen-desktop,
    "desktop-large": $screen-desktop-large,
    "desktop-xlarge": $screen-desktop-xlarge
    // 'min-desktop-xxlarge' : ($screen-desktop-xxlarge ),
    // 'max-mobile' : ($screen-max-mobile),
    // 'max-tablet-portrait' : ($screen-max-tablet-portrait ),
    // 'max-tablet-landscape' : ($screen-max-tablet-landscape ),
    // 'max-desktop' : ($screen-max-desktop ),
    // 'max-desktop-large' : ($screen-max-desktop-large ),
    // 'max-desktop-xlarge' : ($screen-max-desktop-xlarge ))
) !default;

$modes: (
    "min": min-width,
    "max": max-width,
    ) !default;

@mixin breakpoint($mode: "min", $breakpoint: "tablet-landscape") {
    @if map-has-key($modes, $mode) {
        @if map-has-key($breakpoints, $breakpoint) {
            $mediaBreakpoint: null;
            $currentMode: map-get($modes, $mode
        );
        $currentBreakpoint: map-get($breakpoints, $breakpoint);

        @if $currentMode =="max-width" {
            $mediaBreakpoint: calculateMaxWidth($currentBreakpoint);
        }

        @else {
            $mediaBreakpoint: $currentBreakpoint;
        }

        @media screen and (#{$currentMode} : #{$mediaBreakpoint}) {
            @content;
        }
    }

    @else {
        @warn "`#{$breakpoint}` is not a valid breakpoint, available breakpoints: #{map-keys($breakpoints)}.";
    }
}

@else {
    @warn "`#{$mode}` is not a valid mode, available modes: #{map-keys($modes)}.";
}
}

/**
* calculate the max width based on input
*/
@function calculateMaxWidth($breakpoint) {
    $pixel: 0.0625;
    @return #{$breakpoint - $pixel}; // 1px  in em sizing.
}
