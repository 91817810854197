@use './../mixins/index' as mixin;
@use './../base/color' as color;

footer.footer {
    background-color: color.$color-darkblue-soft;
    padding: 8rem 0 4rem;
    color: color.$color-white;

    ul {
        padding-left: 0;
    }

    li {
        list-style-type: none;
        line-height: 1.8;
    }

    h2 {
        margin-bottom: 2rem;
    }
}

.footer__wrapper {
    @include mixin.sectionwrapper();

    &>div:not(.footer__copyright) {
        display: grid;
        gap: 2rem;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: 35% 1fr;
            gap: 5rem;
        }
    }
}

.footer__calls {
    margin-top: 3rem;

    p a {
        text-decoration: underline;
    }
}


.footer__newsletter a {
    font-size: 1.6rem;
}

.footer__info {
    display: grid;
}

.footer__navigation {
    display: grid;

    ul {
        margin: 0;
    }

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(3, 1fr);

        ul:nth-child(2) {
            margin-inline: auto;
        }

        ul:last-child {
            margin-left: auto
        }
    }

    a {
        &.current {
            color: color.$color-blue-trans;
        }
    }
}

.footer__center {
    padding-block: 4rem;
    margin-block: 6rem;
    border-top: .2rem solid rgba(color.$color-white, .4);
    border-bottom: .2rem solid rgba(color.$color-white, .4);

    span,
    a {
        font-size: 1.8rem;
    }
}

.footer__text {
    font-size: 2.4rem;

    a {
        text-decoration: underline;
    }
}

.footer__kunst img {
    max-width: 20rem;
}

.footer__copyright {
    margin-top: 6rem;
    column-span: all;
    @include mixin.flex($justify: flex-end);

    p {
        font-size: 1.5rem;
    }

    a {
        text-decoration: underline
    }
}
