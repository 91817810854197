@use '../mixins/index' as mixin;
@use '../base/index' as base;

.about__list {
    display: grid;
    grid-gap: 4rem;
    padding: 5rem 0;
    grid-template-columns: 1fr;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-gap: 6rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%/2, max(15rem, 15%)), 1fr));
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-gap: 8rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(15rem, 15%)), 1fr));
    }
}

.about__wrapper {
    @include mixin.flex($direction: column, $gap: 0);
    @include mixin.sectionwrapper();
}

.about__link {
    @include mixin.flex($gap: 0, $direction: column, $align: center, $justify: center);
    border-radius: 2rem;
    transition: background-color .2s ease;
}

.about__image {
    width: 100%;
    max-width: 25rem;
    aspect-ratio: 1;
    overflow: hidden;
    margin-bottom: 2rem;
    overflow: hidden;
    transition: transform .2s linear;
    border-radius: 50%;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.about__name {
    opacity: 1;
    font-size: 2.3rem;
    font-weight: 500;
    text-align: center;
    margin: 0 !important;
}

.about__role {
    opacity: 1;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
}
