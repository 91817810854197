@use '../mixins/index' as mixin;
@use './../base/index' as base;


.maps__wrapper {
    @extend %content-split;
}

.map {


    iframe {
        box-shadow: 0px 2px 36px 2px rgba(201, 201, 201, 0.349649);
        width: 100%;
        height: 100%;
        max-height: 40rem;
        min-height: 40rem;
    }

    .button {
        margin-top: 4rem;
        appearance: button;
    }
}

.map__content ul {
    list-style-type: none;
    padding-left: 0;
}