@use '../base/color' as color;
@use '../mixins/index' as mixin;

.modal {
    visibility: hidden;
    position: fixed;
    inset: 0;
    z-index: 1000;
    background-color: rgba(color.$color-blue-trans, .9);
    @include mixin.flex($align: center, $justify: center);

    &:target {
        visibility: visible;
    }
}

.modal__wrapper {
    background-color: color.$color-white;
    padding: 6rem;
    position: relative;
    background-image: url('../../images/bg/swirl-trans/swirl-trans-gray.svg');
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
}

.modal__close {
    position: absolute;
    color: color.$color-white;

    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    @include mixin.flex($align: center, $justify: center);

    span {
        font-size: 3rem;
        margin-top: 1rem;
    }
}