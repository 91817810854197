%worker-image {
    border-radius: 100%;
    box-shadow: 0px 2px 36px 2px rgba(201, 201, 201, 0.349649);
}

.contact__worker {
    .worker__image {
        margin-block: 4rem;


        img {
            @extend %worker-image;
            max-width: 24rem;
            max-height: 24rem;
            aspect-ratio: 1/1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .worker__name {
        margin-bottom: 2rem;
    }

    .worker__content p:not(:first-child) {
        margin-bottom: 0;
    }
}