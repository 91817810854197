@use '../mixins/index' as mixin;

%content__wrapper {
    @include mixin.sectionwrapper(167rem,
        $setDefaultPadding: 0 0,
        $setBreakpointPadding: 'tablet-landscape',
        $setBreakpointPaddingSize: 0 5rem);
}

%content__wrapper--padding {
    @include mixin.sectionwrapper(167rem,
        $setDefaultPadding: 0 2rem,
        $setBreakpointPadding: 'tablet-landscape',
        $setBreakpointPaddingSize: 0 5rem);
}

%content__wrapper--small {
    @include mixin.sectionwrapper(144rem);
}

%heading__underline {
    content: '';
    display: block;
    width: 8.5rem;
    height: .3rem;
    margin: 1.5rem auto 0;
}

%content-split {
    display: grid;
    row-gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: 35% 1fr;

    }
}