@use '../base/color' as color;

p .link {
    font-family: 'Calibre';
    font-weight: 500;
    text-decoration: underline;
}

.link--text-pink {
    color: color.$color-pink-soft;
}