@use '../mixins/index' as mixin;
@use '../base/index' as base;

.filter__search {
    position: relative;
}

.filter__spacer--bottom {
    margin-bottom: 1.5rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-bottom: 3rem;
    }
}

%filter__button {
    @include mixin.flex($align: center, $justify: center);
    position: relative;
    width: 5rem;
    height: 4rem;
    cursor: pointer;
    flex: 0 0 4rem;
}

.artist__filter {
    @include mixin.flex;
    background-color: base.$color-lightblue-trans;
    padding: 3rem 0;

    &--loading {
        pointer-events: none;
        opacity: .5;
    }
}

.filter__wrapper {
    @include mixin.flex($align: center, $justify: space-between, $gap: 0);
    @include mixin.sectionwrapper();
    position: relative;
}

.artist__wrapper {
    @include mixin.flex($direction: column, $gap: 0);
    @include mixin.sectionwrapper();
}

.filter__alphabet {
    @include mixin.flex($gap: 0);

    .field {
        @extend %filter__button;
    }

    input {
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    @include mixin.breakpoint('max', 'desktop') {
        overflow-x: auto;
    }
}

.search__toggle,
.search__close {
    @extend %filter__button;
    z-index: 8;

    &:hover,
    &:focus,
    &:active {
        .toggle__icon {
            transform: scale(1.2);
        }
    }
}

.search__toggle {
    margin-right: 2rem;
}

.search__close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 1.5rem;
    height: 100% !important;
    opacity: 0;
    visibility: hidden;
}

.search__icon,
.toggle__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    will-change: transform;
    transition: transform .2s ease;
}

.search__icon {
    position: absolute;
    top: 1rem;
}

.search__field {
    position: absolute;
    background-color: base.$color-lightblue-trans;
    z-index: 5;
    left: 3rem;
    right: 2rem;
    max-width: 0;
    overflow: hidden;
    transition: all .2s linear;

    &.search--visible {
        max-width: 100%;

        .search__input--invisible {
            opacity: 1;
            visibility: visible;
        }

        .search__close {
            opacity: 1;
            visibility: visible;
        }
    }
}

.search__input {
    width: 100%;
    appearance: none;
    border: 0;
    border-bottom: 2px solid base.$color-black;
    padding: 0 0 0 4rem !important;
    font-size: 1.6rem;
}

.search__input--invisible {
    opacity: 0;
    visibility: hidden;
}


.dropdown__list {
    max-height: 0;
    overflow: hidden;
}

// .checkbox__field {
//     &:not(:last-child) {
//         margin-bottom: 2rem;
//     }
// }

.dropdown__label {
    @include mixin.flex($align: center);
    position: relative;
    height: 4rem;
    font-size: 1.4rem;
    box-shadow: 0 1rem 4rem -1.5rem rgba(base.$color-black, .2);
    padding: 0 2rem;
    font-weight: 500;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &:after {
        content: '';
        background-image: url('../../images/svg/chevron-down.svg');
        background-size: 1.4rem;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 2rem;
        width: 2rem;
        height: 2rem;
    }

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 1.8rem;
    }
}

.dropdown__list {
    transition: all .15s linear;
    padding: 0 2rem;
}

.filter__dropdown--open {
    .dropdown__list {
        overflow-y: auto;
        padding: 2rem 2rem;
        max-height: 75rem;
    }

    .dropdown__label {
        margin-bottom: 0;
    }

    .dropdown__label::after {
        background-image: url(../../images/svg/chevron-down.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 1.4rem;
        content: "";
        height: 2rem;
        position: absolute;
        right: 2rem;
        width: 2rem;
        transform: rotate(180deg);
    }
}

.checkbox__field {
    margin: 1rem 0;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        display: none;
    }

    [type="checkbox"]:not(:checked)+label,
    [type="checkbox"]:checked+label {
        @include mixin.flex($align: center, $gap: 0);
        position: relative;
        cursor: pointer;
        font-size: 1.4rem;

        @include mixin.breakpoint('min', 'desktop-large') {
            font-size: 1.6rem;
        }
    }

    [type="checkbox"]:not(:checked)+label:before,
    [type="checkbox"]:checked+label:before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        flex: 0 0 2rem;
        border: 1px solid #969696;
        background: base.$color-white;
        transition: all .275s;
        margin-right: 1.5rem;
        border-radius: .2rem;
    }

    [type="checkbox"]:checked+label:before {
        background-color: base.$color-pink-soft;
        border-color: #969696;
    }

    [type="checkbox"]:not(:checked)+label:after,
    [type="checkbox"]:checked+label:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='19' viewBox='0 0 25 19'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF' fill-rule='nonzero' stroke='%23FFF' stroke-width='2'%3E%3Cg%3E%3Cpath d='M21.468.32c-.425-.426-1.115-.426-1.54 0L6.877 13.37 1.86 8.353c-.426-.425-1.115-.425-1.54 0-.426.426-.426 1.115 0 1.54l5.786 5.788c.426.425 1.115.425 1.54 0L21.469 1.86c.426-.426.426-1.115 0-1.54z' transform='translate(-115.000000, -1229.000000) translate(116.234043, 1230.500000)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-size: 1.6rem;
        background-repeat: no-repeat;
        display: block;
        width: 1.6rem;
        height: 1.2rem;
        position: absolute;
        left: 0.3rem;
        font-size: 1.4rem;
        color: base.$color-white;
        line-height: 0;
        transition: all .2s;
    }

    [type="checkbox"]:not(:checked)+label:after {
        opacity: 0;
        transform: scale(0) rotate(45deg);
    }

    [type="checkbox"]:checked+label:after {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
}

.radio__field {
    margin: 2rem 0;

    .radio__label {
        display: none;
    }

    .optionset {
        list-style: none;
        padding: 0 0;

        li {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        display: none;
    }

    [type="radio"]:not(:checked)+label,
    [type="radio"]:checked+label {
        @include mixin.flex($align: center);
        position: relative;
        cursor: pointer;
        font-size: 1.4rem;
        height: 3rem;

        @include mixin.breakpoint('min', 'desktop-large') {
            font-size: 1.6rem;
        }
    }

    [type="radio"]:not(:checked)+label:before,
    [type="radio"]:checked+label:before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        flex: 0 0 2rem;
        border: 1px solid base.$color-blue-trans;
        background: base.$color-white;
        transition: all .275s;
        margin-right: 1.5rem;
    }

    [type="radio"]:not(:checked)+label:after,
    [type="radio"]:checked+label:after {
        content: '';
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        left: .4rem;
        font-size: 1.4rem;
        color: base.$color-white;
        line-height: 0;
        transition: all .2s;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        transform: scale(0) rotate(45deg);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
}


.filter__slider {
    margin-top: 8rem;
}

.slider__description {
    margin-top: 1.5rem;
    font-size: 1.4rem;
}

.slider__default {
    --slider-connect-bg: #b69bf7;
    --slider-tooltip-bg: #b69bf7;
    --slider-handle-ring-color: #b69bf7;
    --slider-tooltip-font-size: 1.4rem;
    --slider-tooltip-line-height: 1;
    --slider-tooltip-font-weight: 500;
    --slider-tooltip-py: 1rem;
    --slider-tooltip-px: 2rem;
    --slider-tooltip-arrow-size: .6rem;
    --slider-tooltip-distance: .4rem;
}
