@use '../mixins/index' as mixin;

.banner[data-type="bg--wave"] .image--cover {
    object-fit: none;
}

.image--cover,
.image--contain {
    width: 100%;
    height: 100%;
}

.image--cover {
    object-fit: cover;
}

.image--contain {
    object-fit: contain;
}

.image--maximized {
    object-fit: cover;
    width: 100%;
}

.image--limited {
    object-fit: contain;
    max-width: 100%;
    max-height: 35rem;
    width: auto;
    height: auto;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        max-height: 50rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        max-height: 75rem;
    }
}

.image--limited {
    object-fit: contain;
    max-width: 100%;
    max-height: 32.5rem;
    width: auto;
    height: auto;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        max-height: 45rem;
    }
}