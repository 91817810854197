@use '../mixins/index.scss' as mixin;
@use '../base/color' as color;


.element__title[data-style="center"] {
    text-align: center
}

.element__wrapper[data-style="full-width"] {
    max-width: initial;
    width: 100%;
    padding: 0;
}

.element-block.contentimageblock .element:has(.block[data-style="wave-image"]) {
    padding: 0;
}

.element__border {
    margin-bottom: 4rem;
}
