@use './../mixins/index' as mixin;
@use './../base/color' as color;

.banner__wrapper {
    position: relative;
    @include mixin.sectionwrapper();
    max-height: 60rem;
}

.banner__image {
    display: grid;
    position: relative;
    height: inherit;

    img {
        max-height: 60rem;
    }
}

.banner__content {
    margin-bottom: 8rem !important;
    align-self: end;
    z-index: 2;
    position: relative;
    @include mixin.flex($align: center);
    color: color.$color-white;
    text-shadow: 0 .2rem 3rem rgba(0, 0, 0, 0.37);

    p {
        font-size: 2.2rem;
    }

    a.button:first-child {
        margin-right: 2rem;
    }


    .content__inner--big {
        @include mixin.breakpoint('min', 'tablet-portrait') {
            width: 60%;
        }
    }
}

.banner:not([data-type="normal"]) {
    .image--cover {
        object-fit: cover;
        object-position: 100% 50%;
    }
}



.banner[data-type="normal"] {
    display: grid;
    grid-template: "container" 1fr;
    overflow: hidden;

    min-height: clamp(250px, 50vh, 32rem);
    max-height: 60rem;

    &>* {
        grid-area: container;
    }

    .banner__wrapper {
        @include mixin.flex($align: end);
    }

    @include mixin.breakpoint('min', 'desktop') {
        min-height: 60rem;
    }
}

.banner[data-type="bg--swirl"] {
    display: grid;
    grid-template: "container" 1fr;
    overflow: hidden;
    max-height: clamp(450px, 50vh, 50rem);
    min-height: clamp(450px, 50vh, 50rem);

    &>* {
        grid-area: container;
    }

    .banner__wrapper {
        @include mixin.flex($align: center);
    }


}

.banner[data-type="bg--wave"] {
    display: grid;
    overflow: hidden;

    .banner__image {
        order: 2;
    }

    .banner__wrapper {
        order: 1;
        @include mixin.flex($align: center);
    }

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template: "container" 1fr;
        max-height: clamp(450px, 50vh, 50rem);
        min-height: clamp(450px, 50vh, 50rem);

        &>* {
            grid-area: container;
        }

    }

    .banner__image {
        @include mixin.breakpoint('max', 'tablet-portrait') {
            height: 30rem;
        }

    }

    .banner__content {
        margin-bottom: 2rem;
        width: 100%;

        .content__inner {
            max-width: 60%;
        }
    }
}



.banner[data-image="false"] {
    max-height: clamp(30rem, 50vh, 32rem);
    min-height: clamp(30rem, 50vh, 32rem);

    .banner__content {
        color: color.$color-black;
        padding-bottom: 4rem;
        border-bottom: .1rem solid;
        width: 100%;
    }

    .banner__content .content__inner {
        width: 100%;
    }
}

.banner__arrow {
    z-index: 10;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateY(-50%);
    animation: updownarrow 1.5s ease-in-out infinite;
}


@keyframes updownarrow {
    0% {
        transform: translateY(-60%);
    }

    50% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(-60%);
    }
}
