@use '../base/color' as color;


.contact__socials h2 {
    margin-bottom: 3rem;
}

.contact__text ul {
    margin-bottom: 4rem;
    padding-left: 0;
}

.contact__text li {
    display: block;

    a {
        color: color.$color-pink-soft;
        font-weight: bold;
    }
}

.contact__text a.button:first-child {
    margin-right: 2rem;
}