@use '../base/color' as color;
@use '../mixins/index' as mixin;

.service {
    display: grid;
    gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
        gap: 5rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        grid-template-columns: 60% 40%;
    }

}

.service__image {
    max-width: 60rem;
    position: relative;
    display: grid;

    &[data-layout="has-title"] {
        img {
            grid-area: container;
        }
    }



    .image__title {
        background-color: color.$color-white;
        grid-area: container;
        text-align: center;
        align-self: center;
        max-width: max-content;
        margin-inline: auto;
        padding: 2rem 4rem;
        box-shadow: 0 2px 36px 2px rgba(201, 201, 201, 0.35);

        @include mixin.breakpoint('min', 'desktop') {
            position: absolute;
            top: 50%;
            right: -15rem;
            transform: translateY(-50%);
        }
    }
}

.service__pages {
    @include mixin.breakpoint('min', 'tablet-portrait') {
        max-width: 36rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        max-width: 33rem;
    }
}