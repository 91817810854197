@use './../mixins/index' as mixin;
@use './../base/color' as color;

.block {

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 1.5rem;
    }

    li {
        font-size: 2.5rem;
        line-height: 1.5;
    }

    &__subtitle {
        display: inline-block;
        margin-top: 1rem;
    }
}

.block__image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.block__content {
    padding: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        height: fit-content;
        padding: 3rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        padding: 4rem 6rem;
    }
}

.block__image,
.block__content {
    width: 100%;
}

.block[data-style="rounded-image"] {

    .block__subtitle {
        min-width: 25rem;
        text-align: center;

        @include mixin.breakpoint('min', 'desktop') {
            min-width: 30rem;
        }
    }

    .block__wrapper {
        display: grid;
        gap: 5rem;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: 35% calc(65% - 4rem);
        }

        @include mixin.breakpoint('min', 'desktop') {
            gap: 10rem;
        }
    }

    .block__wrapper[data-image-position="rtl"] {
        direction: rtl;

        .block__content {
            direction: ltr;
        }
    }

    .block__image {
        flex-direction: column;
        position: relative;
        @include mixin.flex($align: center);
        @include mixin.flex($justify: center);
    }

    .block__image img {
        max-width: 25rem;
        max-height: 25rem;
        min-width: 25rem;
        aspect-ratio: 1/1;
        border-radius: 100%;
        box-shadow: 0 0 32px 8px rgba(189, 189, 189, 0.5);

        @include mixin.breakpoint('min', 'desktop') {
            max-width: 30rem;
            max-height: 30rem;
            min-width: 30rem;
        }
    }
}

.block[data-style="squared-images"] {
    .block__wrapper {
        display: grid;
        gap: 5rem;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: 35% 1fr;
            gap: 10rem;
        }
    }

    .block__content {
        padding: 6rem 0 6rem 6rem;
    }

    .block__content--extra-padding {
        padding: 10rem 0 10rem 6rem !important;
    }

    .block__wrapper[data-image-position="rtl"] {
        direction: rtl;

        .block__content {
            direction: ltr;
            padding: 6rem 6rem 6rem 0;
        }
    }

    .block__image img {
        min-height: 33rem;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}

.block[data-style="squared-images-big"] {
    .block__wrapper {
        display: grid;
        grid-row-gap: 2rem;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template-columns: repeat(10, 1fr);
            grid-template-rows: repeat(5, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
    }

    .block__content {
        background-color: color.$color-white;
        box-shadow: 0 0 25px -3px rgb(189 189 189 / 50%);
    }

    .block__wrapper[data-image-position="rtl"] {
        .block__image {
            @include mixin.breakpoint('min', 'tablet-portrait') {
                grid-area: 1/6/5/11;
            }
        }

        .block__content {
            @include mixin.breakpoint('min', 'tablet-portrait') {
                grid-area: 2/1/6/6;
                position: relative;
                padding: 6rem 0rem 6rem 6rem;

                .inner__text {
                    z-index: 11;
                    position: relative;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -6rem;
                    width: 6rem;
                    z-index: 0;
                    background-color: white;
                }
            }
        }
    }

    .block__wrapper[data-image-position="ltr"] {
        .block__image {
            @include mixin.breakpoint('min', 'tablet-portrait') {
                grid-area: 1/1/5/6
            }
        }



        .block__content {
            @include mixin.breakpoint('min', 'tablet-portrait') {
                grid-area: 2/6/6/11;
                position: relative;
                padding: 6rem 6rem 6rem 0;

                .inner__text {
                    z-index: 11;
                    position: relative;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -6rem;
                    width: 6rem;
                    z-index: 0;
                    background-color: white;
                }
            }
        }
    }
}

.block[data-style="wave-image"] {
    .block__wrapper {
        display: grid;

        @include mixin.breakpoint('min', 'tablet-portrait') {
            grid-template: "container" 1fr;
            overflow: hidden;
            max-height: clamp(450px, 50vh, 50rem);
            min-height: 30rem;
            align-items: center;

            &>* {
                grid-area: container;
            }
        }

    }

    .block__image {
        order: 2;
        max-height: inherit;
        position: relative;
    }

    .block__content {
        @include mixin.sectionwrapper();
        z-index: 1;
        order: 1;
        color: color.$color-white;
    }

    .inner__text {
        padding-block: 4rem;


        @include mixin.breakpoint('min', 'tablet-portrait') {
            max-width: 60%;
        }

        @include mixin.breakpoint('min', 'desktop') {
            max-width: 50%;
        }
    }
}

.block__image[data-image-position="top"] {
    align-items: start !important;
}

.block__image[data-image-position="end"] {
    align-items: end !important;
}

.inner__text p a {
    color: color.$color-pink-soft;
    text-decoration: underline;
}
