span[data-tippy-content] {
    position: relative;

    &:after {
        content: "";
        background-image: url("/_resources/themes/businessartservice/images/icons/circle-info-solid.svg");
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: 4px;
    }
}
