@use '../mixins/index' as mixin;
@use '../base/color' as color;
@use './../base/index' as base;

.content__wrapper[data-layout="with-aside"] {
    display: grid;
    gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: 1fr 40rem;
        gap: 5rem;
    }

    aside ul {
        padding-left: 0;

        li {
            position: relative;
            display: flex;
            margin-bottom: .5rem;
            padding-left: 3rem;
        }

        li:before {
            content: "";
            display: block;
            background-image: url('../../images/icons/checkmark.svg');
            width: 2rem;
            height: 2rem;
            margin-top: .3rem;
            position: absolute;
            left: 0;
        }
    }
}

.content__wrapper[data-layout="split"] {
    @extend %content-split;
}