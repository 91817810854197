@use '../mixins/index' as mixin;
@use '../base/index' as base;

.works__wrapper {
    @include mixin.flex($dynamicDirection: true, $breakpoint: 'tablet-landscape');
    @extend %content__wrapper--padding;
    @include mixin.sectionwrapper();
    gap: 5rem;
    position: relative;
    margin-top: 4rem;
}

.works__wrapper--wide {
    @extend %content__wrapper--padding;
}

.works__wrapper--column {
    flex-direction: column;
    margin-bottom: 7.625rem;
}

.works__filter {
    margin-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        flex: 0 0 22.5%;
        margin-bottom: 0;
        align-self: flex-start;
        position: -webkit-sticky;
        position: sticky;
        top: 13rem;
        //overflow-y: auto;
    }
}

.works__list {
    @include mixin.flex($gap: 0, $direction: column);
    width: 100%;

    .product__list:nth-child(even) {
        background: base.$color-pink-soft;
    }
}

.works__grid {
    display: grid;
    grid-gap: 3rem;
    justify-content: center;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 4rem;
    }
}


.works__grid--medium {
    display: grid;
    grid-gap: 3rem;

    @include mixin.breakpoint('min', 'mobile') {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mixin.breakpoint('min', 'desktop') {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5rem;
    }
}

.works__grid--big {
    display: grid;
    grid-gap: 3rem;

    @include mixin.breakpoint('min', 'mobile') {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mixin.breakpoint('min', 'desktop') {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 5rem;
    }
}

.works__content {
    width: 100%;
}

.work {
    padding: 5rem 0;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        padding: 7.5rem 0 5rem;
    }

    @include mixin.breakpoint('min', 'desktop') {
        padding: 15rem 0 5rem;
    }
}

.work__wrapper {
    @extend %content__wrapper--padding;
}

.work__boxed {
    @include mixin.flex($dynamicDirection: true, $breakpoint: 'tablet-landscape');
    padding: 3rem 1rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        padding: 5rem 5rem;
        box-shadow: 0 0 3rem rgba(base.$color-black, .15);
        gap: 7.5rem;
    }

    @include mixin.breakpoint('min', 'desktop-large') {
        padding: 7.5rem 7.5rem;
    }

    @include mixin.breakpoint('min', 'desktop-xlarge') {
        padding: 4rem 10rem;
        justify-content: space-between;
    }
}

.back__wrapper {
    @include mixin.flex($breakpoint: 'tablet-landscape', $direction: row, $justify: start, $align: center, );
    margin-bottom: 1rem;

    &:hover {
        svg {
            transform: translateX(-.7rem);
        }
    }

    svg {
        height: 1.5rem;
        transition: all .2s ease-in-out;
    }

    span {
        margin-left: 1rem;
        font-size: 1.5rem;
    }
}

.work__gallery {
    @include mixin.flex;
    flex: 0 0 35%;
    margin-bottom: 5rem;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        order: 1;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-bottom: 0;
        flex: 0 0 45%;
    }
}

.work__gallery--stacked {
    flex-direction: column;
}

.gallery__big {
    position: relative;
    box-shadow: 0 0 2rem rgba(base.$color-black, .1);
    margin: 0 auto;
    cursor: zoom-in;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        align-self: flex-start;
    }
}

.work__content {
    @include mixin.flex($direction: column);

    @include mixin.breakpoint('max', 'tablet-landscape') {
        order: 2;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        flex: 0 0 45%;
    }
}

.work__information {
    @include mixin.flex($direction: column);
    margin-bottom: 3rem;
    padding-top: 18rem;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        text-align: center;
        padding-top: 0rem;
    }
}

.work__cta {

    @include mixin.breakpoint('max', 'tablet-landscape') {
        margin: 0 0 3rem;
        order: 3;
    }
}

.work__author {
    font-size: 2rem;
    margin: 1rem 0 1.5rem;
}

.work__value {
    font-size: 1.6rem;
    color: base.$color-pink-soft;
    margin-bottom: 2rem;
    font-weight: 500;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        font-size: 1.8rem;
    }
}

.work__details {
    margin: 2rem 0 0rem;
    display: flex;
    flex-direction: column;


    h2 {
        margin-bottom: 4rem;

        @include mixin.breakpoint('min', 'tablet-landscape') {
            max-width: 40%;
            padding-right: 10rem;
        }
    }


    @include mixin.breakpoint('max', 'tablet-landscape') {
        order: 2;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {

        flex-direction: row;
        align-items: center;
    }


    .tabs {
        margin-bottom: 2rem;
        width: 100%;
        text-align: center;
        @include mixin.flex($gap: 4rem);

        @include mixin.breakpoint('max', 'tablet-portrait') {
            border-bottom: .3rem solid base.$color-blue-trans;
        }

        @include mixin.breakpoint('min', 'tablet-portrait') {
            display: none;
        }

        label {
            margin-top: 0;
            font-weight: normal;
            padding-bottom: 1.6rem;
            line-height: 1.08;
            font-family: 'Platform';
            font-weight: 500;
            font-size: 2.2rem;
            cursor: pointer;
            position: relative;
            width: 50%;

            @include mixin.breakpoint('min', 'tablet-portrait') {
                font-size: 2.9rem;
                display: none;
            }
        }
    }

    .panels {
        @include mixin.breakpoint('min', 'tablet-portrait') {
            @include mixin.flex($gap: 10rem);
        }
    }


    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .radio {
        display: none;
    }



    @include mixin.breakpoint('max', 'tablet-portrait') {
        .panel {
            display: none;
            animation: fadein .8s;
        }

        .panel h4 {
            display: none;
        }

        #one:checked~.panels #one-panel,
        #two:checked~.panels #two-panel,
        #three:checked~.panels #three-panel {
            display: block
        }

        #one:checked~.tabs #one-tab,
        #two:checked~.tabs #two-tab,
        #three:checked~.tabs #three-tab {
            &:after {
                content: "";
                border-top: .3rem solid #000;
                position: absolute;
                bottom: -.3rem;
                left: 0;
                right: 0;
            }

        }
    }





}

.work__actions {
    padding-top: 6rem;

    @include mixin.breakpoint('max', 'tablet-landscape') {
        margin: 0 0 5rem;
        order: 1;
        padding-top: 0rem;
    }
}

.work__disclaimer {
    position: relative;
    color: base.$color-blue-trans;
    margin-top: 5rem;

    * {
        font-size: 1.4rem;
    }

    @include mixin.breakpoint('max', 'tablet-landscape') {
        order: 4;
    }
}

.disclaimer__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    transition: all .2s ease;

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.1);
    }
}

.disclaimer__body {
    position: absolute;
    background-color: base.$color-white;
    ;
    box-shadow: 0 0 2rem 0 rgba(base.$color-black, .3);
    padding: 2rem;
    top: -2rem;
    left: 4rem;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;

    &:before {
        content: '';
        position: absolute;
        left: -.8rem;
        border-style: solid;
        border-width: .8rem .8rem .8rem 0;
        border-color: transparent base.$color-white transparent transparent;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        max-width: 44rem;
    }
}

.disclaimer__body--open {
    opacity: 1;
    visibility: visible;
}

.related {
    padding: 5rem 0;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        padding: 7.5rem 0;
    }

    @include mixin.breakpoint('min', 'desktop') {
        padding: 10rem 0;
    }
}

.related__wrapper {
    @extend %content__wrapper--padding;
}

.related__intro {
    text-align: center;
    margin-bottom: 5rem;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-bottom: 7.5rem;
    }
}

.related__list {
    display: grid;
    grid-gap: 3rem;

    @include mixin.breakpoint('max', 'tablet-portrait') {
        padding: 0 2rem;
    }

    @include mixin.breakpoint('min', 'tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mixin.breakpoint('min', 'desktop') {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5rem;
    }
}

.works__infobar {
    @include mixin.flex($dynamicDirection: true, $breakpoint: 'desktop-large', $gap: 0);
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 3rem;

    @include mixin.breakpoint('min', 'desktop-large') {
        align-items: center;
        justify-content: space-between;
        height: 6rem;
        font-size: 1.8rem;
    }
}

.infobar__sorting {
    @include mixin.flex($direction: column, $gap: 0);

    @include mixin.breakpoint('min', 'mobile') {
        @include mixin.flex($direction: row, $align: center, $gap: 0);
    }
}

.infobar__spacer--right {
    margin-right: 0rem;

    @include mixin.breakpoint('min', 'mobile') {
        margin-right: 3rem;
    }
}

.infobar__sorting:nth-child(1) .sorting__label {
    margin-right: .5rem;
}

.sorting__label {
    margin-right: 2rem;
    margin-top: .5rem;
    margin-bottom: 0 !important;
    font-size: 2rem;
    font-weight: 700;
}

.infobar__totals {
    // @include mixin.breakpoint('max', 'desktop-large') {
    //     margin-bottom: 3rem!important;
    // }

    span {
        font-size: 2.4rem;
        font-weight: 500;
    }

    margin-bottom: 0 !important;
}

.sorting__items {
    display: flex;

    .item {
        position: relative;
        border: 0.1rem solid transparent;
        height: 5rem;
        width: 5rem;

        label {
            width: 5rem;
            height: 5rem;
            border-radius: .5rem;
            border: .2rem solid transparent
        }
    }

    .item.active label {
        border: .2rem solid #F1EBE7;
    }

    .item input {
        position: absolute;
        inset: 0;
        border: 0;
        visibility: hidden;
    }

    .item label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    svg {
        width: 2rem;
        height: 2rem;
        margin: 1rem;
        display: block;
    }
}

.sorting__icon {
    position: absolute;
    pointer-events: none;
    margin: 0.8rem 0 0 5rem;
    width: 2.5rem;

    @include mixin.breakpoint('min', 'mobile') {
        margin: 0.8rem 0 0 4rem;
    }

    @include mixin.breakpoint('min', 'desktop-large') {
        margin: 1.6rem 0 0 4rem;
    }
}

.sorting__icon--list {
    margin: 0.5rem 0 0 0;
}

.sorting__dropdown {
    min-width: 20rem;
    width: auto;
    font-size: 1.4rem;
    // margin-left: auto;
    cursor: pointer;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        min-width: 27.5rem;
        margin-left: unset;
    }
}

.sorting__dropdown--medium {
    min-width: 15rem;
}

.sorting__dropdown--small {
    min-width: 15rem;
    margin-bottom: 1rem;
    padding: 0 2rem !important;

    @include mixin.breakpoint('min', 'mobile') {
        min-width: 9rem;
        margin-bottom: 0rem;
        padding: 0 1rem !important;
    }
}

.option__grid {
    background-image: url(../../images/svg/gridicon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.4rem;
    content: "";
    height: 2rem;
    position: absolute;
    right: 2rem;
    width: 2rem;
}

.option__list {
    background-image: url(../../images/svg/chevron-down.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.4rem;
    content: "";
    height: 2rem;
    position: absolute;
    right: 2rem;
    width: 2rem;
}

.sorting__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

}

.show__more,
.more__box {
    @include mixin.flex($direction: column, $align: center, $gap: 0);

    span {
        font-size: 1.6rem;
    }
}

// .show__more::before {
//     content: "";
//     position: absolute;
//     top: -30rem;
//     width: 100%;
//     height: 30rem;
//     display: block;
//     background: rgb(255, 255, 255);
//     background: linear-gradient(0deg, rgb(255, 255, 255) 60%, rgba(9, 9, 121, 0) 100%);
// }

.show__more {
    padding: 3rem 0;
    position: relative;
}

.show__more--centered {
    justify-content: center;
    width: 100%;
}

.show__spacer--bottom {
    margin-bottom: 5rem;
}

.more__box {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        .more__icon {
            transform: translateY(1rem);
        }
    }
}

.more__text {
    @extend %text--uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: .5px;
    margin-bottom: 1rem;
}

.more__icon {
    display: block;
    width: 2rem;
    height: 2rem;
    transition: all .2s ease-in;
}

.image-tabs-selector__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2rem;
    width: 100%;
}

.image-tabs-selector__wrapper img {
    height: 5rem;
    cursor: pointer;
}

.image-tabs-selector__wrapper img:not(:first-child) {
    margin-left: 2rem;
}

.image-tab--hidden {
    display: none;
}

.work__inactive {
    padding: 2rem;
    background-color: base.$color-orange-soft;
    color: base.$color-white;
    font-size: 1.6rem;
    margin-bottom: 5rem;
}

/* card with ribbon */
.ribbon {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 100px;
    text-align: right;
}

/* card with ribbon */
.ribbon span {
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    white-space: nowrap;
    /*width: 115px;*/
    display: block;
    background: #f5431a;
    box-shadow: 0 0 10px 3px #ff6e4e;
    padding: 0 1rem;
    position: absolute;
    top: 20px;
    left: 0;
    font-weight: bold;
    font-size: 1.5rem;
}

.slider__control--hidden {
    .slider__control {
        visibility: hidden;
    }
}

.works__details--list {
    @include mixin.flex($gap: 2.5rem, $direction: column);
    font-size: 2.4rem;
    margin: 0;
    padding: 2rem 0 0 2rem;
}

.artslider__image {
    width: 50%;
    height: 50%;
    margin: auto;
}
