@use '../base/color' as color;

$textColors: 'black', 'white';

@each $color in $textColors {
    .text--color-#{$color} {
        @if ($color =='black') {
            color: color.$color-black !important;
        } @else if ($color =='white') {
            color: color.$color-white !important;
        }
    }
}
