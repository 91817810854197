@use '../base/color' as color;
@use '../mixins/index' as mixin;
$colors: 'pink', 'white', 'darkblue', 'lightblue', 'orange', 'black', 'trans-darkblue', 'trans-lightblue', 'trans-pink', 'trans-orange';
$animations: 'skew-left', 'skew-right', 'jiggle';


header a.button {
    margin-top: 2rem;
    line-height: initial;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        font-size: 1.6rem;
    }

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-top: 0;
    }
}

.button {
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: .5rem;
    display: inline-block;
    border: 0;
    box-shadow: 0;
    appearance: none;
    font-family: 'Platform', serif;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .05rem;
    text-decoration: none !important;

    span {
        display: inline-block;
        line-height: initial;
        font-size: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
        margin-bottom: 0 !important;
    }
}

.button__wrapper {
    margin-top: 4rem;
}

.button__wrapper[data-style="center"] {
    text-align: center;
}


@each $color in $colors {
    .button--bg-#{$color} {
        @if($color =='pink') {
            background-color: color.$color-pink-soft;
        }

        @else if($color =='white') {
            background-color: color.$color-white;
        }

        @else if($color =='darkblue') {
            background-color: color.$color-darkblue-soft;
        }

        @else if($color =='lightblue') {
            background-color: color.$color-lightblue-soft;
        }

        @else if($color =='orange') {
            background-color: color.$color-orange-soft;
        }

        @else if($color =='black') {
            background-color: color.$color-black;
        }

        @else if($color =='trans-darkblue') {
            background-color: color.$color-blue-trans;
        }

        @else if($color =='trans-lightblue') {
            background-color: color.$color-lightblue-trans;
        }

        @else if($color =='trans-pink') {
            background-color: color.$color-pink-trans;
        }

        @else if($color =='trans-orange') {
            background-color: color.$color-orange-trans;
        }
    }

    .button--h-bg-#{$color} {
        &:hover,
        &:focus {
            @if($color =='pink') {
                background-color: color.$color-pink-soft;
            }

            @else if($color =='darkblue') {
                background-color: color.$color-darkblue-soft;
            }

            @else if($color =='lightblue') {
                background-color: color.$color-lightblue-soft;
            }

            @else if($color =='orange') {
                background-color: color.$color-orange-soft;
            }

            @else if($color =='white') {
                background-color: color.$color-white;
            }

            @else if($color =='black') {
                background-color: color.$color-black;
            }

            @else if($color =='trans-darkblue') {
                background-color: color.$color-blue-trans;
            }

            @else if($color =='trans-lightblue') {
                background-color: color.$color-lightblue-trans;
            }

            @else if($color =='trans-pink') {
                background-color: color.$color-pink-trans;
            }

            @else if($color =='trans-orange') {
                background-color: color.$color-orange-trans;
            }
        }
    }

    .button--h-text-#{$color} {
        &:hover,
        &:focus {
            @if($color =='pink') {
                color: color.$color-pink-soft;
            }

            @else if($color =='darkblue') {
                color: color.$color-darkblue-soft;
            }

            @else if($color =='lightblue') {
                color: color.$color-lightblue-soft;
            }

            @else if($color =='orange') {
                color: color.$color-orange-soft;
            }

            @else if($color =='white') {
                color: color.$color-white;
            }

            @else if($color =='black') {
                color: color.$color-black;
            }

            @else if($color =='trans-darkblue') {
                color: color.$color-blue-trans;
            }

            @else if($color =='trans-lightblue') {
                color: color.$color-lightblue-trans;
            }

            @else if($color =='trans-pink') {
                color: color.$color-pink-trans;
            }

            @else if($color =='trans-orange') {
                color: color.$color-orange-trans;
            }
        }
    }

    .button--text-#{$color} {
        @if($color =='black') {
            color: color.$color-black !important;
        }

        @else if($color =='white') {
            color: color.$color-white !important;
        }
    }

    .button--h-text-#{$color} {
        &:hover,
        &:focus {
            @if($color =='pink') {
                color: color.$color-pink-soft;
            }

            @else if($color =='white') {
                color: color.$color-white;
            }
        }
    }
}

@each $animation in $animations {
    .button--animation-#{$animation} {
        transition: all .2s ease-in-out;


        &:hover,
        &:focus {
            @if($animation =='skew-left') {
                transform: skew(8deg);
            }

            @else if($animation =='skew-right') {
                transform: skew(-8deg);
            }

            @else if($animation =='jiggle') {
                animation: 1s hvr-wobble-horizontal ease-in-out
            }
        }

    }
}

@keyframes hvr-wobble-horizontal {
    16% {
        -webkit-transform: translatex(6px);
        transform: translateX(.6rem)
    }

    33% {
        -webkit-transform: translatex(-4px);
        transform: translateX(-.4rem)
    }

    49% {
        -webkit-transform: translatex(3px);
        transform: translateX(.3rem)
    }

    66% {
        -webkit-transform: translatex(-2px);
        transform: translateX(-.2rem)
    }

    83% {
        -webkit-transform: translatex(1px);
        transform: translateX(.1rem)
    }

    100% {
        -webkit-transform: translatex(0);
        transform: translateX(0)
    }
}

input[type="submit"] {
    background-color: color.$color-orange-soft;
}
