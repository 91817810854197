.logo-slider {
    .logo-slider__wrapper {
        position: relative;
    }

    .logo-slider__slider {
        margin: 0 4rem;
    }

    .logo-slider-next,
    .logo-slider-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .logo-slider-next {
        right: 0;
    }

    .logo-slider__image img {
        width: 35%;
        margin-inline: auto;
        height: 50%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}