@use '../mixins/index' as mixin;
@use '../base/color' as color;

.blogs__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 26rem), 1fr));
    gap: 2rem;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        gap: 5rem;
    }
}

.blogsfilter__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

.blogsfilter__wrapper--fade::before {
    content: "";
    position: absolute;
    top: 80%;
    width: 100%;
    height: 30rem;
    display: block;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 60%, rgba(9, 9, 121, 0) 100%);
    pointer-events: none;
}

.relative-holder {
    position: relative;
    overflow: hidden;
}

.blog__date {
    color: color.$color-pink-soft;
}

.blog {
    transition: all .2s ease-in-out;
}

.blog:hover,
.blog:focus {
    transform: scale(1.02);
}

.blog__image {
    margin-bottom: 4rem;
}

.date {
    color: color.$color-pink-soft;
}

.blog__filter {
    @include mixin.flex;
    background-color: color.$color-lightblue-trans;
    padding: 3rem 0;
    @include mixin.sectionwrapper();
}

.blogs__filter {
    @include mixin.sectionwrapper();
}

.blog__image {
    aspect-ratio: 1.5/1;
    margin-bottom: 2rem;

    width: 100%;
    object-fit: cover;

    @include mixin.breakpoint('min', 'tablet-landscape') {
        height: 250px;
    }
}

.blog__dropdown {
    min-width: 20rem;
    width: auto;
    font-size: 1.9rem;
    // margin-left: auto;
    cursor: pointer;
    border: 0px;
    box-shadow: 0px 2px 36px 2px rgba(201, 201, 201, 0.349649);

    @include mixin.breakpoint('min', 'tablet-landscape') {
        margin-left: unset;
    }
}

.blog__card {
    display: flex;
    transition: all .2s ease-in-out;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        flex-basis: calc((100% / 2) - 2rem);
    }

    @include mixin.breakpoint('min', 'desktop') {
        flex-basis: calc((100% / 3) - 2.8rem);
    }
}

.blog__card:hover,
.blog__card:focus {
    transform: scale(1.02);
}

.blogs__infobar {
    @include mixin.flex($dynamicDirection: true, $breakpoint: 'desktop-large', $gap: 0);
    font-size: 1.6rem;
    font-weight: 500;
    margin: 2rem 0 3rem;
    flex-direction: column;
    justify-content: flex-start;

    @include mixin.breakpoint('min', 'tablet-portrait') {
        margin: 3rem 0;
    }

    @include mixin.breakpoint('min', 'desktop-large') {
        align-items: center;
        justify-content: space-between;
        height: 6rem;
        font-size: 1.8rem;
        flex-direction: row;
    }
}

.blogsinfobar__sorting {
    @include mixin.flex($gap: 0, $direction: column);

    @include mixin.breakpoint('min', 'tablet-portrait') {
        flex-direction: row;
    }
}

.blogsinfobar__spacer {
    margin-right: 0rem;
    margin-bottom: 1rem;

    @include mixin.breakpoint('min', 'mobile') {
        margin-right: 3rem;
    }
}
